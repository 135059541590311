/* Base CSS for desktop */
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
}

.item {
  flex: 1; /* Flex grow to distribute space */
  margin: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

/* Responsive CSS for mobile devices */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .item {
    margin: 10px 0; /* Adjust margin for vertical stacking */
  }
}

/* Additional responsive adjustments for very small screens */
@media (max-width: 480px) {
  .container {
    padding: 10px;
  }

  .item {
    padding: 10px;
  }
}
.header {
    background-color: #ffffff; /* Lavender purple */
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
  }
  
  .header .logo a, .header .navigation a {
    color: white;
    text-decoration: none;
    padding: 0 15px;
    font-weight: bold;
  }
  
  .header .navigation ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .header .navigation ul li {
    padding: 0 10px;
  }
  
  .header .auth-links {
    display: flex;
  }
  
  .header .auth-links a {
    background-color: #ffffff; /* Darker shade of lavender */
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 10px;
  }
  
  .header {
    background-color: #E1E1DD; /* Adjusted to match logo background */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: 'Quantico', sans-serif;
  }
  
  .header .logo img {
    height: 60px; /* Adjust size as needed */
  }
  
  .header .navigation ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .header .navigation ul li a {
    color: rgb(31, 31, 31);
    text-decoration: none;
    margin: 0 15px;
    font-weight: bold;
    font-size: 21px;
    transition: color 0.3s ease;
  }
  
  .header .navigation ul li a:hover {
    color: #ce4f00; /* Lavender accent for hover state */
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5%; /* Reduced horizontal padding */
    background-color: #E1E1DD;
    width: 150%; /* Ensure the header spans the full width */
    max-width: 100%; /* Remove any max-width restriction */
    box-sizing: border-box; /* Include padding in the width calculation */
  }
  
  .logo img {
    height: 50px; /* Adjust size as needed */
  }
  
  .navigation ul, .auth-links {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .navigation li, .auth-links a {
    margin: 0 10px;
    color: #fff; /* White text color */
    text-decoration: none;
  }
  
  .auth-links a {
    background-color: #f1f1f1; /* Light background for visibility */
    color: #6F6781; /* Dark text for contrast */
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .auth-link:hover, .auth-link.register:hover {
    background-color: #dedede; /* Slightly darker on hover for interactivity */
  }
  

