/* General Styles */
body {
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
  margin-top: 20px;
  color: #333;
}

.dashboard-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

.priests-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.priest-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

.priest-card:hover {
  transform: translateY(-5px);
}

.priest-card h3 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.priest-card p {
  margin: 5px 0;
  color: #666;
}

.priest-card .priest-info {
  margin-bottom: 20px;
}

.book-now-button {
  display: inline-block;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.book-now-button:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .priests-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .priests-grid {
    grid-template-columns: 1fr;
  }
}
