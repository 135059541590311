/* UserRegistration.css */

/* Setting global styles for the entire form */
body {
  background-color: #FFF3E0; /* Light orange/yellow hue reminiscent of auspicious colors */
  color: #4E342E; /* Rich brown color to match Hindu traditions */
}

form {
  max-width: 500px;
  margin: 80px auto;
  padding: 30px;
  border-radius: 8px;
  background-color: #FFF8E1; /* Light saffron color, traditional and sacred */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #E65100; /* Subtle outline using a bold orange shade */
  position: relative;
}

form::before {
  content: '\1F64F'; /* Praying hands emoji, symbolizing devotion */
  font-size: 50px;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
}

label {
  display: block;
  margin-bottom: 12px;
  font-weight: bold;
  color: #BF360C; /* Deep orange color to match with pooja theme */
}

input[type="text"], input[type="email"], input[type="password"] {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #BCAAA4; /* Warm brown shade, gives a natural look */
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
}

input[type="checkbox"] {
  margin-right: 10px;
}

button {
  width: 100%;
  padding: 15px;
  background-color: #D84315; /* Bright orange to symbolize energy and positivity */
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

button:hover {
  background-color: #BF360C; /* Darken on hover for better UX */
}

/* Adding decorative elements to enhance aesthetics */
form::after {
  content: '';
  position: absolute;
  bottom: -30px;
  left: 50%;
  width: 60px;
  height: 60px;
  background: url('https://example.com/diya-symbol.png'); /* Placeholder for diya symbol to symbolize light */
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-50%);
  opacity: 0.8;
}

/* Styling the checkbox label */
.checkbox-label {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #BF360C;
}

/* Responsive styling */
@media (max-width: 600px) {
  form {
    padding: 20px;
  }

  button {
    padding: 12px;
  }

  form::before {
    font-size: 40px;
    top: -50px;
  }
}