/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Quantico', sans-serif;
  background-color: #FAF3F0;
  color: #3E2723;
  line-height: 1.6;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

/* Home Page Container */
.homepage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Hero Section */
.hero {
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #960000;
}

.hero-left,
.hero-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-content {
  text-align: center;
  max-width: 80%;
}

.hero-right {
  background-color: rgba(255, 255, 255, 0.1);
}

.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
}

.hero-content h1 {
  font-family: 'Quantico', sans-serif;
  font-size: 4rem; /* Responsive heading size */
  color: #fff; /* White for contrast */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Soft shadow for text clarity */
}

.catchphrase {
  font-family: 'Quantico', sans-serif;
  font-size: 2rem;
  color: #e3e3e3; /* Subtle grey */
  margin-top: 15px; /* Adjusted spacing */
  letter-spacing: 1px;
}

.mission-statement {
  font-size: 1.2rem;
  color: #c1c1c1; /* Light grey */
  margin-top: 1rem;
  font-style: italic;
}

.hero-content .hero-buttons {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1rem; /* Space between the buttons */
}

.button-primary,
.button-secondary {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.1s ease;
  width: 120px; /* Fixed width for uniformity */
  text-align: center;
  background-color: transparent; /* Transparent background */
  color: white; /* White text */
  border: 2px solid white; /* White border */
}

.hero-content .button-primary,
.hero-content .button-secondary {
  font-weight: bold; /* Make text bold */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.hero-content .button-primary {
  background-color: #ec5b00; /* Primary button color */
  color: white;
  border: none;
}

.hero-content .button-secondary {
  background-color: #ce4f00c8; /* Primary button color */
  color: white;
  border: none;
}

.hero-content .button-primary:hover,
.hero-content .button-secondary:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Slight white background on hover */
  transform: translateY(-2px);
}

.hero-content .button-primary:hover {
  background-color: #a53f00; /* Darker shade for hover */
}

.hero-content .button-secondary:hover {
  background-color: #a53f00; 
}

.hero-content .button-primary:active,
.hero-content .button-secondary:active {
  transform: translateY(1px);
}

/* Slideshow Section */
.slideshow {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px; /* Space between hero and slideshow */
  padding: 20px 0; /* Padding for some breathing space */
}

iframe {
  width: 960px; /* Fixed width */
  height: 569px; /* Fixed height to maintain aspect ratio */
  border: none; /* Remove iframe border */
  border-radius: 8px; /* Slight rounding for cleaner look */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Soft shadow for iframe */
}

/* Responsive Adjustments */
@media (max-width: 1200px) {
  .hero-content h1 {
    font-size: 3.5rem;
  }

  .hero-content .catchphrase {
    font-size: 1.8rem;
  }

  iframe {
    width: 720px; /* Smaller width on medium devices */
    height: 420px; /* Adjusted height for aspect ratio */
  }
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
  }

  .hero-left,
  .hero-right {
    width: 100%;
  }

  .hero-right {
    padding: 2rem 0;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-content .catchphrase {
    font-size: 1.5rem;
  }

  iframe {
    width: 100%; /* Full width on smaller devices */
    height: auto; /* Automatic height to maintain aspect ratio */
  }

  .slideshow {
    padding: 10px 0; /* Adjusted padding on smaller devices */
  }

  .hero-content .mission-statement {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content .catchphrase {
    font-size: 1.2rem;
  }

  .hero-content .mission-statement {
    font-size: 1rem;
  }
}
