.pujaris {
  padding: 2rem;
  background-color: #f5f5f5;
}

.pujaris h2 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

.pujari-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.pujari-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
}

.pujari-card:hover {
  transform: translateY(-5px);
}

.pujari-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.pujari-card h3 {
  color: #333;
  margin-bottom: 0.5rem;
  text-align: center;
}

.pujari-card p {
  color: #666;
  margin: 0.25rem 0;
  text-align: center;
  font-size: 0.9rem;
}

.book-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-button:hover {
  background-color: #45a049;
}

/* Responsive design for smaller screens */
@media (max-width: 1024px) {
  .pujari-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .pujari-grid {
    grid-template-columns: 1fr;
  }
}
