.testimonials-section {
  background-color: #E1E1DD;
  padding: 4rem 0;
}

.testimonials-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.section-title {
  font-size: 2.5rem;
  color: #CE4F00;
  text-align: center;
  margin-bottom: 3rem;
}

.testimonial-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.testimonial-card {
  background-color: #ffffffdc;
  border-radius: 10px;
  padding: 2rem;
  flex: 1 1 300px;
  max-width: 400px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.quote-icon {
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 10px;
  left: 20px;
  font-family: Georgia, serif;
}

.quote {
  font-size: 1.1rem;
  color: #CE4F00;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
}

.author-info {
  text-align: right;
}

.author {
  font-size: 1.1rem;
  color: #960000;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.role {
  font-size: 0.9rem;
  color: #CE4F00;
}

@media (max-width: 768px) {
  .testimonial-card {
    flex: 1 1 100%;
  }
  
  .section-title {
    font-size: 2rem;
  }
}
