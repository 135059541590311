.about-us-page {
  background-color: #960000;
  min-height: 100vh; /* This ensures the background covers the full viewport height */
  padding: 2rem 0;
}
@import url('https://fonts.googleapis.com/css2?family=Quantico:wght@400;700&display=swap');

.about-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #333;
  line-height: 1.6;
}

.about-us-title {
  text-align: center;
  color: #F5F5F5;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.about-us-section {
  margin-bottom: 3rem;
  background-color: #E1E1DD;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-us-section h2 {
  color: #ce4f00;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.mission p {
  font-size: 1.1rem;
  text-align: center;
}

.how-it-works ol {
  padding-left: 1.5rem;
}

.how-it-works li {
  margin-bottom: 0.5rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.feature {
  text-align: center;
  padding: 1rem;
  background-color: #f8f8f8;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.feature:hover {
  transform: translateY(-5px);
}

.feature i {
  font-size: 2rem;
  color: #ce4f00;
  margin-bottom: 0.5rem;
}

.contact-us {
  text-align: center;
}

.contact-button {
  background-color: #ce4f00;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #a53f00;
}

@media (max-width: 768px) {
  .about-us-container {
    padding: 1rem;
  }

  .about-us-title {
    font-size: 2rem;
  }

  .about-us-section {
    padding: 1.5rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }
}
